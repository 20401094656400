import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../Sign.css';
import Validate from 'validate.js';
import { withRouter } from 'react-router-dom';
import { IconMail } from '@tabler/icons-react';
import { GetCodeAsync } from '../../../Components/Helpers/Authenticator';
import DynamicIcon from '../../../Components/Helpers/DynamicIcon';

function SignInEmail(props) {
    const [emailMessage, setEmailMessage] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [processingDone, setProcessingDone] = useState(false);

    const { email, setEmail } = props

    const constraints = {
        email: {
            presence: {
                message: 'Required'
            },
            email: {
                message: "Please enter valid email"
            }
        }
    };

    const submitSignIn = async e => {
        setProcessing(true);
        let check = Validate({
            email: email
        }, constraints,
            {
                fullMessages: false
            }
        );

        check && setEmailMessage(check?.email[0]);

        if (!check) {
            var result = await GetCodeAsync(email);

            if (!result) {
                setProcessing(false);
                setErrorMessage("Couldn't send code, please try again");
            } else {
                setProcessingDone(true);
                props.handleCodeSent(true);
                return;
            }
        }
        setProcessing(false);
    }

    function handleOnKeyPress(e) {
        if (e && e.charCode === 13) {
            submitSignIn();
        } else {
            setEmailMessage(null)
            setErrorMessage(null)
        }
    }

    function _renderSignButtopn() {
        if (isProcessing || processingDone) {
            return (
                <DynamicIcon type={isProcessing ? 'loadingWhiteCircle' : 'doneLoading'} width={39} height={39} />
            );
        } else {
            return (<>Send code</>);
        }
    }

    return (
        <div className="sign-sec sign-in">
            <div className="sign-body sb-web">
                <DynamicIcon type='hereToHelp' height={200} />
                <div className="sign-header">Hey 👋 We moved Sign in page..</div>
                <a className='new-sign-link' href='https://arvel.app/users/sign-in'>New sign in page</a>
                {/* <div className={(!emailMessage ? 'user-input' : 'user-input error')}>
                    <label>Email</label>
                    <IconMail stroke={2} width='22' color='#b9bec7' className='email-icon' />
                    <input name="email"
                        value={email || ''}
                        type="email"
                        placeholder="Email"
                        onChange={({ target: { value } }) => setEmail(value)}
                        onKeyPress={handleOnKeyPress} />
                    <span className="helper-txt">{emailMessage}</span>
                </div>
                <button className="btn-sign" onClick={submitSignIn}>{_renderSignButtopn()}</button>
                <span className={`error-txt ${errorMessage ? 'sign-in-error-msg-show' : ''}`}>
                    {errorMessage}
                </span> */}
            </div>
        </div>
    );
}

SignInEmail.propTypes = {
    setIsLoggedIn: PropTypes.func.isRequired
}

export default withRouter(SignInEmail)