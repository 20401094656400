import React, { useEffect, useState } from 'react'
import './Profile.css';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import Info from './Components/Info';
import ImageColumn from './Components/Image';
import Header from '../../Components/Header';
import NavigationTabMobile from '../../Components/NavigationTabMobile';
import { SignedInCreatorsGetShortInfo } from '../../Components/Helpers/Queries';

export default function Profile(props) {
    const [creatorShortInfo, setCreatorShortInfo] = useState(null);

    useEffect(function () {
        async function load() {
            let resultCreatorShortInfo = await SignedInCreatorsGetShortInfo()
            setCreatorShortInfo(resultCreatorShortInfo);
        };

        load();
    }, []);

    const _renderMetaTitle = () => {
        if (!creatorShortInfo?.first_name) return

        return <Helmet>
            <title>{`${creatorShortInfo?.first_name}'s profile - Arvel `}</title>
        </Helmet>
    }

    return (
        <>
            {_renderMetaTitle()}
            <div className="profile-container">
                <Header />
                <NavigationTabMobile />
                <Fade>
                    <div className='pc-info-div'>
                        <ImageColumn />
                        <Info />
                    </div>
                </Fade>
            </div>
        </>
    );
}