import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import './index.css';
import { ApolloProvider } from '@apollo/react-hooks';
import client from './ApolloClient';
import Sidebar from './Components/Sidebar';

// pages
import Profile from './Pages/profile/Profile';
import Analytics from './Pages/analytics/Analytics';
import Itineraries from './Pages/itineraries/Itineraries';
import Experiences from './Pages/experiences/Experiences';
import AddExperience from './Pages/add-experience/AddExperience';
import Finance from './Pages/finance/Finance';
import SignIn from './Pages/sign/SignIn';
import NotFound from './Pages/404/404';

import { IsSignedInAsync } from './Components/Helpers/Authenticator';

export default function App(props) {
  let [isLoggedIn, setIsLoggedIn] = useState(false);
  let [isLoading, setLoading] = useState(true);
  const [windowWith, setWindowWidth] = useState(null);
  const [viewWith, setViewWidth] = useState(null);

  useEffect(() => {
    async function effect() {
      const loggedIn = await IsSignedInAsync();
      setIsLoggedIn(loggedIn);
      setLoading(false);
      setWindowWidth(window.screen.width)
      setViewWidth(window.innerWidth)
    }
    effect();
  }, [])

  useEffect(() => {
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 3598676, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, [])

  // To fix bug with redirect on refresh page
  if (isLoading) return <></>

  if (!isLoggedIn) {
    // return public pages if not logged in
    return (
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route exact path="/signin" render={(props) => <SignIn {...props} setIsLoggedIn={setIsLoggedIn} />} />
            <Redirect from='*' to='/signin' />
          </Switch>
        </Router>
      </ApolloProvider>
    )
  } else {
    return (
      <div style={{ display: "flex" }}>
        <ApolloProvider client={client}>
          <Router>
            {isLoggedIn && windowWith > 1099 && viewWith > 1099 ? <Sidebar /> : <></>}
            <Switch>
              <Redirect exact from="/" to="/profile" />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/analytics" component={Analytics} />
              <Route exact path="/itineraries" component={Itineraries} />
              <Route exact path="/experiences" component={Experiences} />
              <Route exact path="/add-experience" component={AddExperience} />
              <Route exact path="/finance" component={Finance} />
              <Route exact path="*" component={NotFound} />
            </Switch>
          </Router>
        </ApolloProvider>
      </div>
    )
  }
}

ReactDOM.render(
  <App></App>,
  document.getElementById('root')
);