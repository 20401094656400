import React, { useState } from 'react';
import '../AddExperience.css';
import { Card, Title, Bold } from "@tremor/react";
import { IconPhotoPlus, IconInfoSquareRounded } from '@tabler/icons-react';
import { GetImageOrientationName, GetImageOrientationRatio } from '../../../Components/HelperFunctions';
import LoadingView from '../../../Components/LoadingView';
import { Popover, Text } from '@mantine/core';

export default function SelecFiles(props) {
    const { isLoading } = props;
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    function handleImagesSelected(e) {
        if (Object.keys(e.target.files).length < 3) {
            setShowErrorMsg('Please select 3 or more images');
            return;
        }

        props.loadingStarted();

        let completeImageObjects = [];

        for (const [key, file] of Object.entries(e.target.files)) {
            const reader = new FileReader();

            reader.onload = function (event) {
                const img = new Image();

                img.onload = function () {
                    const orientationName = GetImageOrientationName(img.width, img.height);
                    const orientationRatio = GetImageOrientationRatio(img.width, img.height);
                    let desiredWidth = 1080;
                    let desiredHeight = desiredWidth / img.width * img.height;
                    if (img.width < desiredWidth) {
                        desiredWidth = img.width;
                        desiredHeight = img.height;
                    }
                    let cropWidth = desiredWidth;
                    let cropHeight = cropWidth / orientationRatio;

                    // Create a new Image element to store the resized image
                    const resizedImg = new Image();

                    // Draw the original image on the resized Image element with the desired dimensions
                    const canvas = document.createElement('canvas');
                    canvas.width = desiredWidth;
                    canvas.height = desiredHeight;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, desiredWidth, desiredHeight);
                    resizedImg.src = canvas.toDataURL();

                    // Use Promise to wait for the resized image to load
                    const waitForResizedImgLoad = new Promise((resolve) => {
                        resizedImg.onload = () => resolve();
                    });

                    waitForResizedImgLoad.then(() => {
                        // Calculate crop dimensions
                        const cropX = (resizedImg.width - cropWidth) / 2;
                        const cropY = (resizedImg.height - cropHeight) / 2;

                        // Create a canvas to draw the cropped image
                        const cropCanvas = document.createElement('canvas');
                        cropCanvas.width = cropWidth;
                        cropCanvas.height = cropHeight;
                        const cropCtx = cropCanvas.getContext('2d');

                        // Draw the resized image on the crop canvas, cropping it
                        cropCtx.drawImage(resizedImg, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

                        // Get the Blob data of the cropped image from the crop canvas
                        cropCanvas.toBlob(function (blob) {
                            // Create a new File object with the Blob data
                            const croppedFile = new File([blob], file.name, { type: file.type });

                            const completeImageObject = {
                                id: key,
                                file: croppedFile,
                                width: cropWidth,
                                height: cropHeight,
                                url: URL.createObjectURL(croppedFile),
                                orientation: orientationName,
                            };

                            completeImageObjects.push(completeImageObject);

                            // Check if all images have been processed
                            if (completeImageObjects.length === Object.keys(e.target.files).length) {
                                props.filesSelected(completeImageObjects);
                            }
                        }, file.type, 1); // Optionally, you can specify the image quality (0.8 in this case)
                    });
                };

                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    const _renderLoading = () => {
        if (!isLoading) return;

        return <LoadingView />
    }

    return (
        <Card className='npc-select-files-card'>
            {_renderLoading()}
            <Title className='npc-select-files-header'>
                <Popover width={300} position='bottom' withArrow shadow="md">
                    <Popover.Target className='secton-hdr-info-container'>
                        <Title style={{ color: 'rgb(0, 0, 0)' }}><Bold>Add new experience</Bold><IconInfoSquareRounded stroke={2} width='18' height='18' color='rgba(83, 100, 113, 0.4)' /></Title>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Text size="md" style={{ textAlign: 'left', marginBottom: 10 }}>We believe that everyone experiences life uniquely, discovering favorite places to eat, hike, swim, travel, play games, and more.</Text>
                        <Text size="md" style={{ textAlign: 'left' }}>To celebrate these unique journeys, we built Arvel where you can capture and share your joyful moments, guiding others to have similar experiences.</Text>
                    </Popover.Dropdown>
                </Popover>
            </Title>
            <div className='npc-select-files-body'>
                <IconPhotoPlus stroke={0.65} width='150' height='150' color={'rgb(55, 65, 81)'} />
                {
                    showErrorMsg
                        ? <h3 className='npc-error-msg'>{showErrorMsg}</h3>
                        : <h3>3 or more photos</h3>
                }
                <label className="apc-select-files">
                    <input type="file" accept='image/png, image/jpg' multiple onChange={handleImagesSelected} />
                    Select from computer
                </label>
            </div>
        </Card>
    );
}