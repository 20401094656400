import React, { useState } from 'react';
import './Sign.css';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';
import LogoTransperent from '../../Images/logo_transparent.png';
import SignInEmail from './Components/SignInEmail';
import SignInCode from './Components/SignInCode';

export default function SignIn(props) {
    const [codeSent, setCodeSent] = useState(false);
    const [email, setEmail] = useState(null);

    return (
        <>
            <Helmet>
                <title>{'Partner Sign in | Arvel'}</title>
            </Helmet>
            <div id="main" className="main sign-in-page">
                <div className="div-logo">
                    <a href="https://arvel.app">
                        <img alt="logo" src={LogoTransperent} />
                    </a>
                </div>
                <div className="bg-square" />
                <Fade>
                    {
                        codeSent
                            ? <SignInCode email={email}
                                setIsLoggedIn={props.setIsLoggedIn}
                                handleLogin={() => props.setIsLoggedIn(true)} />
                            : <SignInEmail email={email}
                                setEmail={setEmail}
                                handleCodeSent={() => setCodeSent(true)} />
                    }
                </Fade>
            </div>
        </>
    );
}