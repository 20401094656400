import React, { useState, useEffect } from 'react';
import { IconX } from '@tabler/icons-react';

const DragAndDropImage = (props) => {
    const { img, imagesCount } = props;
    const [image, setImage] = useState(null);
    const [imgSelected, setImgSelected] = useState(false);

    useEffect(function () {
        setImage(img);
    }, [img]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (event.target.id !== `dad-image-${img?.id}` && event.target.id !== `dad-image-remove-btn-${img?.id}` && event.target.id !== `dad-image-remove-icon-${img?.id}`) setImgSelected(false)
        }

        window.addEventListener('click', handleClickOutside);
        return () => window.removeEventListener('click', handleClickOutside);
    }, []);

    const handleImageSelected = () => {
        if (!imgSelected) props.handleImageSelected(img)
        setImgSelected(!imgSelected)
    }

    const handleImageRemoved = () => props.handleImageRemoved(img)

    return (
        <div alt='place'
            id={`dad-image-${image?.id}`}
            key={'place_ri_' + image?.id}
            style={{ backgroundImage: `url('${image?.url}')` }}
            className='background-image apc-image-reorder-img'
            onClick={handleImageSelected}>
            {imagesCount > 3 && imgSelected && <button id={`dad-image-remove-btn-${image?.id}`} className='dad-img-remove-btn' onClick={handleImageRemoved}>
                <IconX id={`dad-image-cls-icon-${image?.id}`} stroke={2} width='16' color='#fff' />
            </button>}
            {imgSelected && <div className='dad-image-selected-frame'/>}
        </div>
    );
};

export default DragAndDropImage;
