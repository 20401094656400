import React, { useState, useEffect } from 'react';
import '../AddExperience.css';

export default function AddInfoLocation(props) {
    const { errorMsg, existingPlaceLocationObj, placeExist } = props;
    const [locationObj, setLocationObj] = useState({
        city: null,
        province: null,
        country: null,
        coords: {
            lat: null,
            lng: null
        }
    });

    const [messageObj, setMessageObj] = useState({
        coords: {
            lat: null,
            lng: null
        }
    });

    useEffect(function () {
        setMessageObj(errorMsg)
    }, [errorMsg]);

    useEffect(function () {
        setLocationObj(existingPlaceLocationObj)
    }, [existingPlaceLocationObj]);

    const handleOnKeyPress = (e) => {
        setMessageObj({
            ...messageObj,
            coords: {
                lat: null,
                lng: null
            }
        });
    }

    const handleOnBlur = () => props.handleCoordsInput(locationObj?.coords?.lat, locationObj?.coords?.lng)

    return (
        <div className='npc-place-location-div'>
            <div className='npc-location-coords-div'>
                <div className={(!messageObj?.coords?.lat ? 'user-input' : 'user-input error')}>
                    <label>Latitude</label>
                    <input name="name"
                        type="text"
                        disabled={placeExist}
                        value={locationObj?.coords?.lat || ''}
                        placeholder="51.040386"
                        onChange={({ target: { value } }) => setLocationObj({
                            ...locationObj,
                            coords: {
                                ...locationObj.coords,
                                lat: value
                            }
                        })}
                        onKeyPress={handleOnKeyPress}
                        onBlur={handleOnBlur} />
                    <span className="helper-txt">{messageObj?.coords?.lat}</span>
                </div>
                <div className={(!messageObj?.coords?.lng ? 'user-input' : 'user-input error')}>
                    <label>Longitude</label>
                    <input name="name"
                        type="text"
                        disabled={placeExist}
                        value={locationObj?.coords?.lng || ''}
                        placeholder="-114.068787"
                        onChange={({ target: { value } }) => setLocationObj({
                            ...locationObj,
                            coords: {
                                ...locationObj.coords,
                                lng: value
                            }
                        })}
                        onKeyPress={handleOnKeyPress}
                        onBlur={handleOnBlur} />
                    <span className="helper-txt">{messageObj?.coords?.lng}</span>
                </div>
            </div>

            {placeExist && existingPlaceLocationObj && <div className='npc-location-info-div'>
                <div className='user-input'>
                    <label>City / town</label>
                    <input name="name"
                        type="text"
                        disabled={true}
                        value={locationObj?.city || ''} />
                </div>
                <div className='user-input'>
                    <label>Province / State/ Area</label>
                    <input name="name"
                        type="text"
                        disabled={true}
                        value={locationObj?.province || ''} />
                </div>
                <div className='user-input'>
                    <label>Country</label>
                    <input name="country"
                        type="text"
                        disabled={true}
                        value={locationObj?.country || ''} />
                </div>
            </div>}

        </div>
    );
}