import React from 'react';
import '../index.css';
import Fade from 'react-reveal/Fade';
import DynamicIcon from '../Components/Helpers/DynamicIcon';

export default function LoadingView(props) {
    return (
        <Fade>
            <div className='loading-div'>
                <DynamicIcon type='loadingWhiteCircle' width={39} height={39} />
            </div>
        </Fade>
    );
}