import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragAndDropImage from './DragAndDropImage';

const DragAndDropReorderPopUp = (props) => {
    const { images } = props;
    const [imageList, setImageList] = useState(null);

    useEffect(function () {
        setImageList(images);
    }, [images]);

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const updatedList = [...imageList];
        const [reorderedItem] = updatedList.splice(result.source.index, 1);
        updatedList.splice(result.destination.index, 0, reorderedItem);
        setImageList(updatedList);
        props.handleListSorted(updatedList);
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        background: isDragging ? '#fff' : 'transparent',
        borderRadius: 2,
        ...draggableStyle,
    });

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="imageList" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ display: 'flex',  flexDirection: 'row', flexWrap: 'wrap'}}
                    >
                        {imageList?.map((image, index) => (
                            <Draggable key={'Draggable-' + image.id} draggableId={image.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        className='draggable-img'
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <DragAndDropImage key={`dad-img-${image.id}`} 
                                            imagesCount={imageList?.length || 0}
                                            img={image}
                                            handleImageSelected={(img) => props.handleImageSelected(img)}
                                            handleImageRemoved={(img) => props.handleImageRemoved(img)} />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragAndDropReorderPopUp;
