
import { createUploadLink } from 'apollo-upload-client'
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { GetAuthToken } from './Components/Helpers/LocalStorage';
import { setContext } from '@apollo/client/link/context';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { getMainDefinition } from '@apollo/client/utilities';

const cache = new InMemoryCache();

const link = createUploadLink({
    uri: process.env.REACT_APP_BACKEND_API,
});

const authLink = setContext((_, { headers }) => {
    const token = GetAuthToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

  // const wsLink = new WebSocketLink({
  //   uri: 'wss://dashboard.arvel.app/subscriptions',
  //   options: {
  //     reconnect: true,
  //     connectionParams: {
  //       authToken: GetAuthToken(),
  //     },
  //   }
  // });

  // const splitLink = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return (
  //       definition.kind === 'OperationDefinition' &&
  //       definition.operation === 'subscription'
  //     );
  //   },
  //   // wsLink,
  //   authLink.concat(link),
  // );

const client = new ApolloClient({
    uri: process.env.REACT_APP_BACKEND_API,
    link: ApolloLink.from([authLink, link]),
    cache: cache,
});

export default client;