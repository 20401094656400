import React, { useState } from 'react';
import '../AddExperience.css';
import { Card, Title, Bold } from "@tremor/react";
import { IconArrowLeft, IconChevronDown } from '@tabler/icons-react';
import { Accordion } from '@mantine/core';
import AddInfoName from './AddInfoName';
import AddInfoLocation from './AddInfoLocation';
import AddInfoSummary from './AddInfoSummary';
import AddInfoTypes from './AddInfoTypes';
import Validate from 'validate.js';
import { GetNodeLocation, GetNodeTypes } from '../../../Components/Helpers/Queries';
import DynamicIcon from '../../../Components/Helpers/DynamicIcon';

export default function AddInfo(props) {
    const [loading, setLoading] = useState(false);
    const [accordionItemShowed, setAccordionItemShowed] = useState('0');
    const [existingPlaceID, setExistingPlaceID] = useState(null);
    const [name, setName] = useState(null);
    const [types, setTypes] = useState(null);
    const [summary, setSummary] = useState(null);
    const [locationObj, setLocationObj] = useState({
        city: null,
        province: null,
        country: null,
        area: null,
        coords: {
            lat: null,
            lng: null
        }
    });
    const [errorMsgObj, setErrorMsgObj] = useState({
        name: null,
        location: {
            coords: {
                lat: null,
                lng: null
            }
        },
        types: null,
        summary: null
    });

    const constraints = {
        name: {
            presence: {
                message: 'Required'
            }
        },
        location_coord_lat: {
            presence: {
                message: 'Required'
            },
            numericality: {
                onlyInteger: false,
                message: "Number only"
            }
        },
        location_coord_lng: {
            presence: {
                message: 'Required'
            },
            numericality: {
                onlyInteger: false,
                message: "Number only"
            }
        },
        types: {
            presence: {
                message: 'Required'
            }
        },
        summary: {
            presence: {
                message: 'Required'
            }
        },
    };

    async function handlePlaceExist(placeId) {
        let resultNodeLocation = await GetNodeLocation(placeId)
        let resultNodeTypes = await GetNodeTypes(placeId)

        setTypes(resultNodeTypes)
        setExistingPlaceID(placeId)
        setLocationObj({
            ...locationObj,
            city: resultNodeLocation?.city,
            province: resultNodeLocation?.province,
            country: resultNodeLocation?.country,
            coords: {
                lat: resultNodeLocation?.lat,
                lng: resultNodeLocation?.lng
            }
        })
    }

    const handleNameChange = (name) => {
        setName(name)

        if (existingPlaceID) {
            setLocationObj({
                ...locationObj,
                coords: {
                    lat: null,
                    lng: null
                }
            })
            setTypes(null)
            setExistingPlaceID(null)
        }

        clearErrorObj()
    }

    async function handleAddPlace() {
        setLoading(true)

        let check = Validate({
            name: name,
            location_coord_lat: locationObj?.coords?.lat,
            location_coord_lng: locationObj?.coords?.lng,
            types: types,
            summary: summary
        }, constraints, { fullMessages: false })

        if (check) {
            console.log("check: ", check)

            setErrorMsgObj({
                ...errorMsgObj,
                name: check?.name,
                location: {
                    coords: {
                        lat: check?.location_coord_lat,
                        lng: check?.location_coord_lng
                    }
                },
                types: check?.types,
                summary: check?.summary
            })
            setLoading(false)
            openAccordionItemWithError(check)
            return
        }

        try {
            let res = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${locationObj?.coords?.lat}&longitude=${locationObj?.coords?.lng}&localityLanguage=en`);
            var bigData_result = await res.json();

            setLocationObj({
                ...locationObj,
                coords: {
                    city: bigData_result?.city,
                    province: bigData_result?.principalSubdivision,
                    country: bigData_result?.countryName,
                    area: getNodeArea(bigData_result?.principalSubdivision)
                }
            })
        } catch (e) {
            setLoading(false);
            console.error("big data location data fetch error: ", e);
            return;
        }

        let finalTypeList = []
        if (existingPlaceID) for (let i = 0; i < types.length; i++) finalTypeList.push(types[i].id)
        else finalTypeList = types

        props.handleAddNewPlace({
            original_node_id: existingPlaceID,
            name: name,
            summary: summary,
            types: finalTypeList,
            location: {
                lat: Number(locationObj?.coords?.lat),
                lng: Number(locationObj?.coords.lng),
                city: bigData_result?.city,
                province: bigData_result?.principalSubdivision,
                country: bigData_result?.countryName,
                area: getNodeArea(bigData_result?.principalSubdivision)
            }
        })

        setTimeout(() => {
            setLoading(false)
        }
            , 500)
    }

    const handleCoordsInput = (lat, lng) => {
        setLocationObj({
            ...locationObj,
            coords: {
                lat: lat,
                lng: lng
            }
        })
        clearErrorObj()
    }

    const handleTypesInput = (types) => {
        setTypes(types)
        clearErrorObj()
    }

    const handleSummaryInput = (summary) => {
        setSummary(summary)
        clearErrorObj()
    }

    const getNodeArea = (province) => {
        if (province === 'Alberta') return 'Alberta'
        else if (province === 'British Columbia') return 'British Columbia'
        else if (province === 'Ontario') return 'Ontario'
        else return 'Worldwide'
    }

    const openAccordionItemWithError = (errorObj) => {
        if (errorObj?.name) setAccordionItemShowed('0')
        else if (errorObj?.location_coord_lat || errorObj?.location_coord_lng) setAccordionItemShowed('1')
        else if (errorObj?.types) setAccordionItemShowed('2')
        else if (errorObj?.summary) setAccordionItemShowed('3')
    }

    const clearErrorObj = () => {
        setErrorMsgObj({
            name: null,
            location: {
                coords: {
                    lat: null,
                    lng: null
                }
            },
            types: null,
            caption: null
        })
    }

    const _renderAddButton = () => {
        if (loading) return <DynamicIcon type='loading' width={26} height={26} />
        else return 'Add place'
    }

    return (
        <Card className='npc-add-info-card'>
            <Title className='npc-add-info-header'>
                <button className='npc-add-info-back-btn' onClick={() => props.handleBack()}>
                    <IconArrowLeft stroke={2.2} width='30' color={'rgb(55, 65, 81)'} />
                </button>
                <Bold>Add new place</Bold>
                <button className='npc-add-info-next-btn' onClick={handleAddPlace} disabled={loading}>{_renderAddButton()}</button>
            </Title>
            <Accordion className='accorion-info-add-container' value={accordionItemShowed} onChange={setAccordionItemShowed} chevron={<IconChevronDown stroke={2.2} width='22' color={'rgb(55, 65, 81)'} />}>
                <Accordion.Item value={'0'}>
                    <Accordion.Control style={{ backgroundColor: '#f7f9f9' }}><Title>Name</Title></Accordion.Control>
                    <Accordion.Panel>
                        <AddInfoName errorMsg={errorMsgObj?.name} handlePlaceExist={handlePlaceExist} handleNameChange={handleNameChange} />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={'1'}>
                    <Accordion.Control style={{ backgroundColor: '#f7f9f9' }}><Title>Location</Title></Accordion.Control>
                    <Accordion.Panel>
                        <AddInfoLocation errorMsg={errorMsgObj?.location}
                            placeExist={existingPlaceID}
                            existingPlaceLocationObj={locationObj}
                            handleCoordsInput={handleCoordsInput} />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={'2'}>
                    <Accordion.Control style={{ backgroundColor: '#f7f9f9' }}><Title>Categories</Title></Accordion.Control>
                    <Accordion.Panel>
                        <AddInfoTypes errorMsg={errorMsgObj?.types}
                            placeExist={existingPlaceID}
                            handleTypesInput={handleTypesInput}
                            existingPlaceTypes={types} />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={'3'} className='npc-summary'>
                    <Accordion.Control style={{ backgroundColor: '#f7f9f9' }}><Title>Caption</Title></Accordion.Control>
                    <Accordion.Panel>
                        <AddInfoSummary errorMsg={errorMsgObj?.summary} handleSummaryInput={handleSummaryInput} />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Card>
    );
}