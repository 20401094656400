import React, { useState, useEffect } from 'react';
import './Experiences.css';
import { Helmet } from 'react-helmet';
import Place from './Components/Place';
import { SignedInCreatorsGetShortInfo, GetCreatorsPlaces } from '../../Components/Helpers/Queries';
import InfiniteScroll from 'react-infinite-scroll-component';
import DynamicIcon from '../../Components/Helpers/DynamicIcon';
import { IconCameraPin } from '@tabler/icons-react';
import Header from '../../Components/Header';
import NavigationTabMobile from '../../Components/NavigationTabMobile';

export default function Experiences(props) {
    const WINDOW_WIDTH = window.innerWidth;
    const [isLoading, setIsLoading] = useState(true)
    const [creatorIG, setCreatorIG] = useState(null)
    const [places, setPlaces] = useState(null)
    const [pageToken, setPageToken] = useState(null)

    useEffect(function () {
        async function load() {
            let resultCreatorShortInfo = await SignedInCreatorsGetShortInfo()
            let resultCreatorPlaces = await GetCreatorsPlaces(GetPageSize(), resultCreatorShortInfo?.instagram)

            setTimeout(() => {
                setPlaces(resultCreatorPlaces?.nodes)
                setPageToken(resultCreatorPlaces?.pagination?.after)
                setCreatorIG(resultCreatorShortInfo?.instagram)
                setIsLoading(false)
            }
                , 800)
        };

        load();
    }, []);

    const GetPageSize = () => {
        if (WINDOW_WIDTH < 1480) return 6
        else if (WINDOW_WIDTH < 1840) return 9
        else if (WINDOW_WIDTH < 2200) return 15
        else return 25
    }

    async function handleGetMorePlaces() {
        let resultCreatorPlaces = await GetCreatorsPlaces(GetPageSize(), creatorIG, pageToken)
        setPlaces(places.concat(resultCreatorPlaces?.nodes))
        setPageToken(resultCreatorPlaces?.pagination?.after)
    }

    const _renderContent = () => {
        if (isLoading) return <div className='pc-is-loading-section'><DynamicIcon width={50} height={50} type='loading' /></div>

        if (!places || places?.length < 1) return <div className='pc-empty-section'>
            <IconCameraPin stroke={1} width='120' height='120' color='rgba(0, 0, 0, 0.7)' />
            <p>When you share places, they will appear here</p>
        </div>

        return <InfiniteScroll
            className='infinite-scroll-places-div'
            dataLength={places?.length}
            next={handleGetMorePlaces}
            hasMore={pageToken > 0}
            scrollThreshold='80%'
            loader={<div className='nl-loading-icon-div'><DynamicIcon width={50} height={50} type='loading' /></div>}
        >
            <div className='places-div'>
                {places?.map((place, index) => (
                    <Place key={`place-div-${index}`} place={place} />
                ))}
            </div>
        </InfiniteScroll>
    }

    return (
        <>
            <Helmet>
                <title>{'Places | Partner Portal'}</title>
            </Helmet>
            <div className="places-container">
                <Header/>
                <NavigationTabMobile/>
                {_renderContent()}
            </div>
        </>
    );
}