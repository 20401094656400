import React, { useState, useEffect } from 'react';
import '../Experiences.css';
import { Modal } from '@mantine/core';
import { IconMapPin } from '@tabler/icons-react';
import ImagesSection from './EditPlaceModalImages';
import DynamicIcon from '../../../Components/Helpers/DynamicIcon';
import { Title, Bold } from "@tremor/react";
import { UpdateCreatorsPlaceSummary, UpdateCreatorsPlaceMedia } from '../../../Components/Helpers/Mutations';
import Validate from 'validate.js';

export default function EditPlaceModal(props) {
    const { place, showModal } = props;
    const [show, setShow] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [placeObj, setPlaceObj] = useState(null);
    const [isSummaryUpdated, setIsSummaryUpdated] = useState(false);
    const [isMediaUpdated, setIsMediaUpdated] = useState(false);
    const [updatedMedia, setUpdatedMedia] = useState(null);
    const [summary, setSummary] = useState(null);
    const [summaryError, setSummaryError] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [error, setError] = useState(false);
    const [windowWith, setWindowWidth] = useState(null);

    const constraints = {
        summary: {
            presence: {
                message: 'Required'
            }
        }
    };

    useEffect(function () {
        setShow(showModal)
        setPlaceObj(place)
        setSummary(place?.summary)
        setWindowWidth(window.screen.width)
    }, [showModal]);

    const handleClose = () => {
        setShow(false)
        props.handleHideModal()
        setTimeout(() => { setInitLoading(true) }, 300)
        setIsSummaryUpdated(false)
        setIsMediaUpdated(false)
    }

    const _renderLoading = () => {
        if (!initLoading) return

        return <div className='pd-modal-loading-cover'>
            <DynamicIcon type='loading' width={65} height={65} />
        </div>
    }

    const handleImgListSorted = (sortedImgList) => {
        setUpdatedMedia(sortedImgList)

        let tmpIsUpdated = false
        for (let i = 0; i < placeObj?.media?.summer?.length; i++)
            if (placeObj?.media?.summer[i] !== sortedImgList[i]?.url) {
                tmpIsUpdated = true
                break
            }

        setIsMediaUpdated(tmpIsUpdated)
    }

    const handleSummaryChanged = (summary) => {
        setSummary(summary)
        setIsSummaryUpdated(summary !== placeObj?.summary)
    }

    async function handleSave() {
        let tmpUpdatedList = []
        setIsUpdating(true)

        if (isSummaryUpdated) {
            let check = Validate({
                summary: summary.length === 0 ? null : summary
            }, constraints, { fullMessages: false });

            if (check) {
                setSummaryError(check?.summary)
                setIsUpdating(false)
                return
            }

            const resultSummaryUpdated = await UpdateCreatorsPlaceSummary(placeObj?.id, summary)
            if (!resultSummaryUpdated) {
                setError('Oops..Something went wrong, please try again')
                setIsUpdating(false)
                return
            }

            props.handlePlaceSummaryUpdated(summary)
        }

        if (isMediaUpdated) {
            for (let i = 0; i < updatedMedia?.length; i++) tmpUpdatedList.push(updatedMedia[i]?.url)

            const resultMediaUpdated = await UpdateCreatorsPlaceMedia(placeObj?.id, tmpUpdatedList)
            if (!resultMediaUpdated) {
                setError('Oops..Something went wrong, please try again')
                setIsUpdating(false)
                return
            }

            props.handlePlaceMediaUpdated(tmpUpdatedList)
        }

        setTimeout(() => {
            setIsUpdating(false)
            setIsUpdated(true)
            setTimeout(() => {
                setIsUpdated(false)
                handleClose()
            }
                , 2300)
        }
            , 1800)
    }

    const _renderSaveButton = () => {
        if (isUpdated) return <DynamicIcon type='doneCercleCheckMark' width={28} height={28} />
        else if (isUpdating) return <DynamicIcon type='loadingWhiteCircle' width={26} height={26} />
        else return 'Save'
    }

    return (
        <Modal
            centered={true}
            fullScreen={windowWith > 600 ? true : false}
            withCloseButton={false}
            opened={show}
            onClose={handleClose}
            className='place-details-modal'>
            <div className='pd-modal-container'>
                {_renderLoading()}
                <div className='pd-modal-header'>
                    <div className='pd-location-div'>
                        <IconMapPin stroke={2} width='22' />
                        <p>{placeObj?.name + ', ' + placeObj?.country}</p>
                    </div>
                    <div className='cancel-save-btns-div'>
                        <button className='cancel-button' onClick={handleClose} disabled={isUpdating || isUpdated}>Cancel</button>
                        <button style={{ opacity: ((isSummaryUpdated || isMediaUpdated) && !isUpdating & !isUpdated ? 1 : 0.6) }}
                            className='save-button'
                            onClick={handleSave}
                            disabled={(!isSummaryUpdated && !isMediaUpdated) || isUpdating || isUpdated}>{_renderSaveButton()}</button>
                    </div>
                </div>
                <ImagesSection imagesOrg={placeObj?.media?.summer}
                    initLoadingDone={() => setTimeout(() => { setInitLoading(false) }, 300)}
                    listSorted={handleImgListSorted} />
                <div className='pd-modal-summary-div'>
                    <Title style={{ marginBottom: 10 }}>
                        <Bold>About your experience</Bold>
                    </Title>
                    <div className={(!summaryError ? 'user-input' : 'user-input error')}>
                        <textarea name="summary"
                            value={summary || ''}
                            type="text"
                            placeholder="Type here.."
                            onChange={({ target: { value } }) => handleSummaryChanged(value)}
                            onKeyPress={() => setSummaryError(false)} />
                        <span className="helper-txt">{summaryError}</span>
                    </div>
                </div>
            </div>
        </Modal>
    );
}