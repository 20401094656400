import React, { useState, useEffect } from 'react';
import '../AddExperience.css';
import { Chip } from '@mantine/core';
import { CapitalizeFirstLetter } from '../../../Components/HelperFunctions';
import { GetNodeAllTypes } from '../../../Components/Helpers/Queries';

export default function AddInfoCategories(props) {
    const { errorMsg, existingPlaceTypes, placeExist } = props;
    const [typesObjList, setTypesObjList] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [error, setError] = useState(null);

    useEffect(function () {
        setError(errorMsg)
    }, [errorMsg]);

    useEffect(function () {
        if(placeExist){
            let tmpTypeList = []
            for (let i = 0; i < existingPlaceTypes.length; i++) tmpTypeList.push(existingPlaceTypes[i].name)

            setSelectedTypes(tmpTypeList)
        }
    }, [placeExist, existingPlaceTypes]);

    useEffect(() => {
        async function load() {
            let resultNodeTypes = await GetNodeAllTypes()
            setTypesObjList(resultNodeTypes)
        };

        load();
    }, []);

    const handleTypesInput = (types) => {
        setSelectedTypes(types)

        let typesIdList = []
        for (let i = 0; i < types?.length; i++)
            for (let j = 0; j < typesObjList?.length; j++)
                if (types[i] === typesObjList[j].name) typesIdList.push(typesObjList[j].id)

        props.handleTypesInput(typesIdList)
    }

    return (
        <div className='npc-place-types-container'>
            {!placeExist && <label className='npc-place-container-label' style={{ color: (error ? '#e70000' : '') }}>Select 1 or more</label>}
            <div className='npc-place-types-div'>
                <Chip.Group multiple
                    value={selectedTypes}
                    onChange={(value) =>
                        handleTypesInput(value)
                    }>
                    {typesObjList?.map(function (type, index) {
                        return <Chip disabled={placeExist} className='npm-place-type-chip' key={`place-category-${index}`} size="md" value={type?.name}>
                            <img alt="type-img" src={type?.img} />
                            {CapitalizeFirstLetter(type?.name)}
                        </Chip>
                    })}
                </Chip.Group>
                {error && <p className='npc-place-types-error'>{error}</p>}
            </div>
        </div>
    );
}