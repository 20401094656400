import { Title } from "@tremor/react";
import { Popover, Text } from '@mantine/core';
import { IconInfoSquareRounded } from '@tabler/icons-react';

export function GetImageOrientationName(width, height) {
    if (!width || !height) return;

    const ratio = width / height

    if (ratio > 1.3) return 'landscape'             // 16 : 9
    else if (ratio < 0.9) return 'portrait'         // 4 : 5
    else return 'square'                            // 1 : 1
};

export function GetImageOrientationRatio(width, height) {
    if (!width || !height) return;

    const ratio = width / height

    if (ratio > 1.3) return 16 / 9                  // 16 : 9
    else if (ratio < 0.9) return 4 / 5                // 4 : 5
    else return 1                                   // 1 : 1
};

export function CapitalizeFirstLetter(string) {
    if (!string) return;

    return string?.charAt(0).toUpperCase() + string?.slice(1)
};

export function ArrayOnlyUnique(value, index, array) {
    return array.indexOf(value) === index
};

export function SectionHeaderPopUpDescription(header, width, position, description) {
    return <Popover width={width} position={position} withArrow shadow="md">
        <Popover.Target className='secton-hdr-info-container'>
            <Title style={{color: 'rgb(0, 0, 0)'}}>{header}<IconInfoSquareRounded stroke={2} width='18' height='18' color='rgba(83, 100, 113, 0.4)' /></Title>
        </Popover.Target>
        <Popover.Dropdown>
            <Text size="md">{description}</Text>
        </Popover.Dropdown>
    </Popover>
};