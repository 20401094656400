import React, { useState, useEffect } from 'react';
import '../Experiences.css';
import { GetImageOrientationName} from '../../../Components/HelperFunctions';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import ImageReorderPopUp from '../../add-experience/Components/ImageReorderPopUp';

export default function EditPlaceModalImages(props) {
    const { imagesOrg } = props;
    const [images, setImages] = useState(null);
    const [displayImgIndex, setDisplayImgIndex] = useState(0);
    const [showPreviousBtn, setShowPreviousBtn] = useState(false);
    const [showNextBtn, setShowNextBtn] = useState(true);

    useEffect(() => {
        const loadImages = async () => {
            const updateImgList = await Promise.all(
                imagesOrg?.map((url, index) =>
                    new Promise((resolve) => {
                        const image = new Image();
                        image.src = url;
                        image.onload = () =>
                            resolve({ id: (index + 1).toString(), url: url, width: image.width, height: image.height });
                    })
                )
            );

            setImages(updateImgList)
            props.initLoadingDone(true)
        };

        loadImages();
    }, [imagesOrg]);

    const handleNextImg = () => {
        const tmpIndex = displayImgIndex + 1;

        if (tmpIndex === images.length - 1) setShowNextBtn(false)
        setDisplayImgIndex(tmpIndex)
        setShowPreviousBtn(true)
    }

    const handlePreviousImg = () => {
        const tmpIndex = displayImgIndex - 1;

        if (tmpIndex === 0) setShowPreviousBtn(false)
        setDisplayImgIndex(tmpIndex)
        setShowNextBtn(true)
    }

    const handleListSorted = (sortedList) => {
        setImages(sortedList)
        props.listSorted(sortedList)
    }

    const handleImageSelected = (img) => {
        for (let i = 0; i < images?.length; i++) if (images[i].id === img.id) {
            setDisplayImgIndex(i)
            if (i === 0) {
                setShowPreviousBtn(false)
                setShowNextBtn(true)
            } else if (i === images?.length - 1) {
                setShowPreviousBtn(true)
                setShowNextBtn(false)
            } else {
                setShowPreviousBtn(true)
                setShowNextBtn(true)
            }
        }
    }

    const _renderImage = (index, img) => {
        if (!img) return
        const imgOrientation = GetImageOrientationName(img.width, img.height)
        let imgClass = ''

        if (imgOrientation === 'square') imgClass = 'pd-modal-img-square'
        else if (imgOrientation === 'landscape') imgClass = 'pd-modal-img-landscape'
        else if (imgOrientation === 'portrait') imgClass = 'pd-modal-img-portrait'

        return <div alt='place' key={'place-img-' + index} style={{ backgroundImage: `url('${img?.url}')`, display: (index === displayImgIndex ? '' : 'none') }} className={`background-image ${imgClass}`} />
    }

    const _renderIndicator = (index, isLast) => {
        return <div key={'indicator_' + index} style={{ backgroundColor: (index === displayImgIndex ? 'rgb(63, 163, 240)' : 'rgb(168, 168, 168)'), marginRight: (isLast ? 4 : 0) }} className='img-indicator' />
    }

    return (
        <div className='pd-images-container'>
            {images && images?.map(function (img, index) {
                return _renderImage(index, img)
            })}
            <div className='image-indicators-div'>
                {images && images?.map(function (img, index) {
                    return _renderIndicator(index, index !== images?.length - 1)
                })}
            </div>
            {images?.length > 1 && showPreviousBtn && <button className='image-slide-left-btn' onClick={handlePreviousImg}>
                <IconChevronLeft stroke={2} width='25' color='#fff' />
            </button>}
            {images?.length > 1 && showNextBtn && <button className='image-slide-right-btn' onClick={handleNextImg}>
                <IconChevronRight stroke={2} width='25' color='#fff' />
            </button>}
            <ImageReorderPopUp files={images}
                handleImageSelected={handleImageSelected}
                handleListSorted={handleListSorted} />
        </div>
    );
}