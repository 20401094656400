import React, { useState, useEffect } from 'react';
import '../Itineraries.css';
import { Modal } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { Title, Bold, Text } from "@tremor/react";
import { GetCreatorsItineraryOrderDetails } from '../../../Components/Helpers/Queries';
import { SectionHeaderPopUpDescription } from '../../../Components/HelperFunctions';

export default function DetailsModal(props) {
    const { itineraryId } = props;
    const [show, setShow] = useState(false);
    const [itineraryDetails, setItineraryDetails] = useState(null);

    useEffect(function () {
        async function load() {
            let resultItineraryOrderDetails = await GetCreatorsItineraryOrderDetails(itineraryId)
            setItineraryDetails(resultItineraryOrderDetails)
        };

        load();
    }, [show]);

    const _renderQuestion = (questionObj, index) => {
        return <>
            <Title><Bold>{index + '. ' + questionObj?.question}</Bold></Title>
            <Text className='ic-id-question-response'>{_renderQuestionResponse(questionObj?.response)}</Text>
        </>
    }

    const _renderQuestionResponse = (response) => {
        if (Array.isArray(response)) {
            return response?.map(function (value, index) {
                if (index === response.length - 1) return ' and ' + value
                else return value + ', '
            })
        } else return response
    };

    return (
        <>
            <button className='ic-cell-see-more' onClick={() => setShow(true)}>see more</button>
            <Modal
                centered={true}
                size='xl'
                withCloseButton={false}
                opened={show}
                onClose={() => setShow(false)}
                className='itinerary-details-modal'>
                <button className='ic-close-btn' onClick={() => setShow(false)}>
                    <IconX stroke={2.5} width='20' height='20' color='rgb(217, 52, 113)' />
                </button>
                <div className='idm-sq-section-header-container'>
                    {SectionHeaderPopUpDescription('Itinerary survey response', 220, 'bottom', "Here you'll find the responses to itinerary survey. This information is needed to understand users' interests and preferences better to build the best trip possible.")}
                </div>
                {
                    itineraryDetails?.survey_response.map(function (questionObj, index) {
                        return <div key={`ic-id-question-div-${index}`} className='ic-id-question-div'>
                            {_renderQuestion(questionObj, index + 1)}
                        </div>
                    })
                }
            </Modal>
        </>
    );
}