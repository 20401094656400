import React, { useState, useEffect } from 'react'
import '../Profile.css';
import { Menu, MenuItem } from '@material-ui/core';
import { IconPencil, IconCheck } from '@tabler/icons-react';
import { Card } from "@tremor/react";
import { SignedInCreatorsGetShortInfo, GetCreatorsInfo } from '../../../Components/Helpers/Queries';
import { UpdateCreatorsInfo } from '../../../Components/Helpers/Mutations';
import DynamicIcon from '../../../Components/Helpers/DynamicIcon';
import Validate from 'validate.js';
import { SectionHeaderPopUpDescription } from '../../../Components/HelperFunctions'

export default function Info() {
    const [editInfo, setEditInfo] = useState(false);
    const [updateable, setUpdateable] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false)
    const [isUpdated, setIsUpdated] = useState(false)
    const [error, setError] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const memberCountMenuOpen = Boolean(anchorEl);
    const handleMemberCountMenuClick = (event) => setAnchorEl(event.currentTarget);
    const handleCloseMemberCountMenu = () => setAnchorEl(null);

    const [name, setName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [memberCount, setMemberCount] = useState(1);
    const [city, setCity] = useState(null);
    const [country, setCountry] = useState(null);
    const [bio, setBio] = useState(null);
    const [initCreatorObj, setInitCreatorObj] = useState(null);
    const [stateObj, setMessage] = useState({
        fnameMessage: null,
        lnameMessage: null,
        cityMessage: null,
        countryMessage: null,
        bioMessage: null
    });

    const constraints = {
        name: {
            presence: {
                message: 'Required'
            }
        },
        last_name: {
            presence: {
                message: 'Required'
            }
        },
        city: {
            presence: {
                message: 'Required'
            }
        },
        country: {
            presence: {
                message: 'Required'
            }
        },
        bio: {
            presence: {
                message: 'Required'
            }
        }

    };

    useEffect(function () {
        async function load() {
            let resultCreatorShortInfo = await SignedInCreatorsGetShortInfo()
            let resultCreatorLongInfo = await GetCreatorsInfo(resultCreatorShortInfo?.instagram)

            setName(resultCreatorLongInfo?.first_name)
            setLastName(resultCreatorLongInfo?.last_name)
            setMemberCount(resultCreatorLongInfo?.member_count)
            setCity(resultCreatorLongInfo?.city)
            setCountry(resultCreatorLongInfo?.country)
            setBio(resultCreatorLongInfo?.bio)
            setInitCreatorObj(resultCreatorLongInfo)
        };

        load();
    }, [editInfo]);

    useEffect(function () {
        if (editInfo
            && (name !== initCreatorObj?.first_name
                || lastName !== initCreatorObj?.last_name
                || memberCount !== initCreatorObj?.member_count
                || city !== initCreatorObj?.city
                || country !== initCreatorObj?.country
                || bio !== initCreatorObj?.bio)) setUpdateable(true)
        else setUpdateable(false);

    }, [name, lastName, editInfo, memberCount, bio, city, country, initCreatorObj]);

    async function handleSave() {
        setIsUpdating(true)

        let check = Validate({
            name: name.length < 1 ? null : name,
            last_name: lastName.length < 1 ? null : lastName,
            city: city.length < 1 ? null : city,
            country: country.length < 1 ? null : country,
            bio: bio.length < 1 ? null : bio
        }, constraints, { fullMessages: false });

        if (check) {
            setMessage({
                ...stateObj,
                fnameMessage: check.name,
                lnameMessage: check.last_name,
                cityMessage: check.city,
                countryMessage: check.country,
                bioMessage: check.bio
            });
            setIsUpdating(false)
            return
        }

        const resultInfoUpdate = await UpdateCreatorsInfo({
            first_name: name !== initCreatorObj?.first_name ? name : null,
            last_name: lastName !== initCreatorObj?.last_name ? lastName : null,
            member_count: memberCount !== initCreatorObj?.member_count ? memberCount : null,
            city: city !== initCreatorObj?.city ? city : null,
            country: country !== initCreatorObj?.country ? country : null,
            bio: bio !== initCreatorObj?.bio ? bio : null
        })

        if (!resultInfoUpdate) {
            setError('Oops..Something went wrong, please try again')
            setIsUpdating(false)
            return
        }

        setTimeout(() => {
            setIsUpdating(false)
            setIsUpdated(true)
            setTimeout(() => {
                setIsUpdated(false)
                setEditInfo(false)
                setUpdateable(false)
            }
                , 2300)
        }
            , 1800)
    }

    const handleCancelSave = () => {
        setName(initCreatorObj?.first_name);
        setLastName(initCreatorObj?.last_name);
        setMemberCount(initCreatorObj?.member_count);
        setCity(initCreatorObj?.city);
        setCountry(initCreatorObj?.country);
        setBio(initCreatorObj?.bio);

        setEditInfo(false);
    }

    const handleOnKeyPress = (e) => {
        setMessage({
            ...stateObj,
            fnameMessage: null,
            lnameMessage: null,
            cityMessage: null,
            countryMessage: null,
            bioMessage: null
        });
    }

    const handleSelectMemberCountMenu = (count) => {
        setMemberCount(count);
        handleCloseMemberCountMenu();
    }

    const _renderSaveButton = () => {
        if (isUpdated) return <DynamicIcon type='doneCercleCheckMark' width={28} height={28} />
        else if (isUpdating) return <DynamicIcon type='loadingWhiteCircle' width={26} height={26} />
        else return 'Save'
    }

    return (
        <Card className='pc-info-paper'>
            <div className='pc-info-header'>
                {SectionHeaderPopUpDescription("About", 300, 'bottom', "In this section, you'll find details about yourself, location, and bio. Everything you share here will be visible on your Arvel profile.")}
                {
                    !editInfo
                        ? <button className='edit-button' onClick={() => setEditInfo(true)}>Edit <IconPencil stroke={2} width='17' color='rgb(64, 68, 82)'
                            className='user-icon' /></button>
                        : <div className='cancel-save-btns-div'>
                            <button className='cancel-button' onClick={handleCancelSave}>Cancel</button>
                            <button style={{ opacity: ((updateable && !isUpdating && !isUpdated) ? 1 : 0.6) }} className='save-button' onClick={handleSave} disabled={!updateable || isUpdating || isUpdated}>{_renderSaveButton()}</button>
                        </div>
                }
            </div>
            <div className='pc-info-main'>
                <div className='pc-info-line-1'>
                    <div className={(!stateObj?.fnameMessage ? 'user-input first-name' : 'user-input first-name error')}>
                        <label>Name</label>
                        <input name="name"
                            disabled={!editInfo || isUpdating || isUpdated}
                            value={name || ''}
                            type="text"
                            placeholder="Name"
                            onChange={({ target: { value } }) => setName(value)}
                            onKeyPress={handleOnKeyPress} />
                        <span className="helper-txt">{stateObj?.fnameMessage}</span>
                    </div>
                    <div className={(!stateObj?.lnameMessage ? 'user-input last-name' : 'user-input last-name error')}>
                        <label>Last name</label>
                        <input name="last-name"
                            disabled={!editInfo || isUpdating || isUpdated}
                            value={lastName || ''}
                            type="text"
                            placeholder="Last name"
                            onChange={({ target: { value } }) => setLastName(value)}
                            onKeyPress={handleOnKeyPress} />
                        <span className="helper-txt">{stateObj?.lnameMessage}</span>
                    </div>
                    {/* <div className='pc-info-mc-div'>
                        <label>Member count</label>
                        <button style={{ backgroundColor: (!editInfo ? '#fafafa' : '#fff') }} disabled={!editInfo} onClick={handleMemberCountMenuClick}>{memberCount}</button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={memberCountMenuOpen}
                            onClose={handleCloseMemberCountMenu}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleSelectMemberCountMenu(1)}>
                                1 <IconCheck stroke={3} width='17' color={memberCount === 1 ? 'rgb(63, 163, 240)' : '#fff'} style={{ marginLeft: 5 }} />
                            </MenuItem>
                            <MenuItem onClick={() => handleSelectMemberCountMenu(2)}>
                                2 <IconCheck stroke={3} width='17' color={memberCount === 2 ? 'rgb(63, 163, 240)' : '#fff'} style={{ marginLeft: 5 }} />
                            </MenuItem>
                            <MenuItem onClick={() => handleSelectMemberCountMenu(3)}>
                                3 <IconCheck stroke={3} width='17' color={memberCount === 3 ? 'rgb(63, 163, 240)' : '#fff'} style={{ marginLeft: 5 }} />
                            </MenuItem>
                        </Menu>
                    </div> */}
                </div>
                <div className='pc-info-line-2'>
                    <div className={(!stateObj?.cityMessage ? 'user-input city' : 'user-input  city error')}>
                        <label>City / province / area</label>
                        <input name="area"
                            disabled={!editInfo || isUpdating || isUpdated}
                            value={city || ''}
                            type="text"
                            placeholder="City or area"
                            onChange={({ target: { value } }) => setCity(value)}
                            onKeyPress={handleOnKeyPress} />
                        <span className="helper-txt">{stateObj?.cityMessage}</span>
                    </div>
                    <div className={(!stateObj?.countryMessage ? 'user-input country' : 'user-input country error')}>
                        <label>Country</label>
                        <input name="last-name"
                            disabled={!editInfo || isUpdating || isUpdated}
                            value={country || ''}
                            type="text"
                            placeholder="Country"
                            onChange={({ target: { value } }) => setCountry(value)}
                            onKeyPress={handleOnKeyPress} />
                        <span className="helper-txt">{stateObj?.countryMessage}</span>
                    </div>
                </div>
                <div className='pc-info-line-3'>
                    <div className={(!stateObj?.bioMessage ? 'user-input bio' : 'user-input bio error')}>
                        <label>Bio</label>
                        <textarea name="bio"
                            style={{ overflow: (!editInfo ? 'hidden' : 'scroll') }}
                            disabled={!editInfo || isUpdating || isUpdated}
                            value={bio || ''}
                            type="text"
                            placeholder="Bio"
                            onChange={({ target: { value } }) => setBio(value)}
                            onKeyPress={handleOnKeyPress} />
                        <span className="helper-txt">{stateObj?.bioMessage}</span>
                    </div>
                </div>
            </div>
        </Card>
    );
}