import React, { useEffect, useState } from 'react';
import '../AddExperience.css';
import { Card, Title, Bold } from "@tremor/react";
import { IconArrowLeft, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import LoadingView from '../../../Components/LoadingView';
import ReorderPopUp from './ImageReorderPopUp';

export default function ReviewFiles(props) {
    const { isLoading, files } = props;
    const [orderedList, setOrderedList] = useState(null);
    const [displayImgIndex, setDisplayImgIndex] = useState(0);
    const [showPreviousBtn, setShowPreviousBtn] = useState(false);
    const [showNextBtn, setShowNextBtn] = useState(true);

    useEffect(function () {
        setOrderedList(files);
    }, [files]);

    const handleNextImg = () => {
        const tmpIndex = displayImgIndex + 1;

        if (tmpIndex === orderedList.length - 1) setShowNextBtn(false)
        setDisplayImgIndex(tmpIndex)
        setShowPreviousBtn(true)
    }

    const handlePreviousImg = () => {
        const tmpIndex = displayImgIndex - 1;

        if (tmpIndex === 0) setShowPreviousBtn(false)
        setDisplayImgIndex(tmpIndex)
        setShowNextBtn(true)
    }

    const _renderImage = (index, img) => {
        if (!img) return
        let imgClass = '';

        if (img?.orientation === 'square') imgClass = 'npm-review-img-square'
        else if (img?.orientation === 'landscape') imgClass = 'npm-review-img-landscape'
        else if (img?.orientation === 'portrait') imgClass = 'npm-review-img-portrait'

        return <div alt='place' key={'place_' + index} style={{ backgroundImage: `url('${img?.url}')`, display: (index === displayImgIndex ? '' : 'none') }} className={`background-image ${imgClass}`} />
    }

    const _renderIndicator = (index, isLast) => {
        return <div key={'indicator_' + index} style={{ backgroundColor: (index === displayImgIndex ? 'rgb(63, 163, 240)' : 'rgb(168, 168, 168)'), marginRight: (isLast ? 4 : 0) }} className='img-indicator' />
    }

    const _renderLoading = () => {
        if (!isLoading) return;

        return <LoadingView />
    }

    const handleImageSelected = (img) => {
        for (let i = 0; i < orderedList?.length; i++) if (orderedList[i].id === img.id) {
            setDisplayImgIndex(i)
            if (i === 0) {
                setShowPreviousBtn(false)
                setShowNextBtn(true)
            } else if (i === orderedList?.length - 1) {
                setShowPreviousBtn(true)
                setShowNextBtn(false)
            } else{
                setShowPreviousBtn(true)
                setShowNextBtn(true)
            }
        }
    }

    const handleImageRemoved = (img) => {
        for (let i = 0; i < orderedList?.length; i++) if (orderedList[i].id === img.id && i === orderedList?.length - 1) setShowPreviousBtn(false)
        setDisplayImgIndex(0)
        setShowNextBtn(true)
        props.handleImageRemoved(img)
    }

    return (
        <Card className='npc-review-files-card'>
            {_renderLoading()}
            <Title className='npc-review-files-header'>
                <button className='npc-review-back-btn' onClick={() => props.handleBack()}>
                    <IconArrowLeft stroke={2.2} width='30' color={'rgb(55, 65, 81)'} />
                </button>
                <Bold>Review images</Bold>
                <button className='npc-review-next-btn' onClick={() => props.handleNextClicked()}>Next</button>
            </Title>
            <div className='npm-review-files-body'>
                <div className='npm-review-img-div'>
                    {orderedList && orderedList?.map(function (img, index) {
                        return _renderImage(index, img)
                    })}
                    <div className='image-indicators-div'>
                        {orderedList && orderedList?.map(function (img, index) {
                            return _renderIndicator(index, index !== orderedList?.length - 1)
                        })}
                    </div>
                    {orderedList?.length > 1 && showPreviousBtn && <button className='image-slide-left-btn' onClick={handlePreviousImg}>
                        <IconChevronLeft stroke={2} width='25' color='#fff' />
                    </button>}
                    {orderedList?.length > 1 && showNextBtn && <button className='image-slide-right-btn' onClick={handleNextImg}>
                        <IconChevronRight stroke={2} width='25' color='#fff' />
                    </button>}
                    <ReorderPopUp files={orderedList}
                        handleImageSelected={handleImageSelected}
                        handleImageRemoved={(img) => handleImageRemoved(img)}
                        handleListSorted={(list) => props.handleListSorted(list)} />
                </div>
            </div>
        </Card>
    );
}