import React, { useState, useEffect } from 'react';
import '../Experiences.css';
import { IconMapPin, IconPencil, IconSend, IconEye } from '@tabler/icons-react';
import moment from "moment";
import EditPlaceModal from './EditPlaceModal';

export default function Place(props) {
    const { place } = props
    const [placeObj, setPlaceObj] = useState(null)
    const [showEditModal, setShowEditModa] = useState(false)
    const [analyticsObj, setAnalyticsObj] = useState({
        view: 0,
        share: 0,
        addToTrip: 0,
        addToBucketlist: 0
    });

    useEffect(function () {
        setPlaceObj(place)

        for (let i = 0; i < place?.analytics?.length; i++) {
            const analytics = place?.analytics[i]
            if (analytics.key === 'view') setAnalyticsObj({ ...analyticsObj, view: analytics.value })
            else if (analytics?.key === 'share') setAnalyticsObj({ ...analyticsObj, share: analytics.value })
            else if (analytics?.key === 'addToTrip') setAnalyticsObj({ ...analyticsObj, addToTrip: analytics.value })
            else if (analytics?.key === 'addToBucketlist') setAnalyticsObj({ ...analyticsObj, addToBucketlist: analytics.value })
        }
    }, [place]);

    const handleEditPlace = () => setShowEditModa(!showEditModal)

    const _renderSummary = (place) => {
        let maxLength = 75;
        const tmpSummary = place?.summary

        if (tmpSummary?.length > maxLength) {
            return <p className='pcp-summary' onClick={() => handleEditPlace(place)}>
                {tmpSummary?.replace('\n', ' ').substring(0, tmpSummary?.replace('\n', ' ').substring(0, maxLength).lastIndexOf(' ')) + '...'}
                <span>more</span>
            </p>
        } else {
            return <p className='pcp-summary' onClick={() => handleEditPlace(place)}>{tmpSummary?.replace('\n', ' ')}</p>
        }
    }

    return (
        <div className='pc-place-div'>
            <div className='pcp-location-div'>
                <IconMapPin stroke={1.5} width='18' />
                <p>{placeObj?.name + ', ' + placeObj?.country}</p>
            </div>
            <div className='pcp-images-div-main'>
                <button className='edit-button' onClick={handleEditPlace}>Edit <IconPencil stroke={2} width='17' color='rgb(64, 68, 82)' /></button>
                <div className='pcp-image-1 background-image' style={{ backgroundImage: `url('${placeObj?.media?.summer[0]}')` }} />
                <div className='pcp-images-div-secondary'>
                    <div className='pcp-image-2 background-image' style={{ backgroundImage: `url('${placeObj?.media?.summer[1]}')` }} />
                    <div className='pcp-image-3 background-image' style={{ backgroundImage: `url('${placeObj?.media?.summer[2]}')` }} />
                </div>
            </div>
            <div className='pcp-stats-div'>
                <div className='pcp-views'>
                    <IconEye stroke={2} width='24' />
                    <p>{analyticsObj?.view.toLocaleString()}</p>
                </div>
                <div className='pcp-shares'>
                    <IconSend stroke={2} width='20' />
                    <p>{analyticsObj?.share.toLocaleString()}</p>
                </div>
            </div>
            {_renderSummary(placeObj)}
            <p className='pcp-date-div'>{moment(placeObj?.created_at).format('MMM D, YYYY')}</p>
            <EditPlaceModal
                place={placeObj}
                showModal={showEditModal}
                handleHideModal={() => setShowEditModa(false)}
                handlePlaceSummaryUpdated={(summary) => {
                    setPlaceObj({
                        ...placeObj,
                        summary: summary
                    })
                }}
                handlePlaceMediaUpdated={(updatedMedia) => {
                    setPlaceObj({
                        ...placeObj,
                        media: {
                            ...placeObj,
                            summer: updatedMedia
                        }
                    })
                }}/>
        </div>
    );
}