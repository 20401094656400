import React, { useState, useRef, useEffect } from 'react';
import '../AddExperience.css';

export default function AddInfoSummary(props) {
    const { errorMsg } = props;
    const textareaRef = useRef(null);
    const [summary, setSummary] = useState(null);
    const [characterCnt, setCharacterCnt] = useState(0);
    const [error, setError] = useState(null);

    useEffect(function () {
        setError(errorMsg)

        console.log("errorMsg: ", errorMsg)
    }, [errorMsg]);

    const handleChange = (event) => {
        if(event.target.value.length > 2200){
            setError("Pasted text is too long")
            return
        }
        
        setSummary(event.target.value)
        setCharacterCnt(event.target.value.length)
        adjustTextareaHeight()
        props.handleSummaryInput(event.target.value)
    };

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current
        textarea.style.height = 'auto'
        textarea.style.height = `${textarea.scrollHeight}px`
    };

    return (
        <div style={{padding: '20px 0 25px 0', margin: 0}} className={(!error ? 'npc-place-summary-input user-input' : 'npc-place-summary-input user-input error')}>
            <textarea
                ref={textareaRef}
                value={summary || ''}
                type="text"
                placeholder="Write about your experience.."
                onChange={handleChange}
                onKeyPress={() => setError(null)} />
            <p className='npc-caption-character-cnt'>{characterCnt + '/' + (2200).toLocaleString()}</p>
            {error && <p className='npc-place-summary-error'>{error}</p>}
        </div>
    );
}