import React, { useState, useEffect } from 'react';
import './Finance.css';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import { Card, Title, Metric, Text, Bold } from "@tremor/react";
import moment from "moment";
import { GetCreatorsItineraryOrders, SignedInCreatorsGetShortInfo, GetCreatorsInfo } from '../../Components/Helpers/Queries';
import { SectionHeaderPopUpDescription } from '../../Components/HelperFunctions'
import { IconFolderSearch } from '@tabler/icons-react';
import Header from '../../Components/Header';
import NavigationTabMobile from '../../Components/NavigationTabMobile';
import ItineraryDetails from './Components/DetailsModal';

export default function Finance() {
    const [totPaid, setTotPaid] = useState(0);
    const [totToBePaid, setTotToBePaid] = useState(0);
    const [itineraryHistory, setItineraryHistory] = useState(null);
    const [itineraryArvelFee, setItineraryArvelFee] = useState(null);
    const [windowWith, setWindowWidth] = useState(null);
    const [creatorShortInfo, setCreatorShortInfo] = useState(null);

    useEffect(function () {
        async function load() {
            let resultItineraryOrders = await GetCreatorsItineraryOrders()
            let resultCreatorShortInfo = await SignedInCreatorsGetShortInfo()
            let resultCreatorLongInfo = await GetCreatorsInfo(resultCreatorShortInfo?.instagram)

            setItineraryHistory(resultItineraryOrders)
            setItineraryArvelFee(resultCreatorLongInfo?.itinerary_arvel_fee)
            handleCalcTotals(resultItineraryOrders, resultCreatorLongInfo?.itinerary_arvel_fee)
            setWindowWidth(window.screen.width)
            setCreatorShortInfo(resultCreatorShortInfo)
        };

        load();
    }, []);

    const handleCalcTotals = (orderHistory, fee) => {
        let totToBePaid = 0
        let paidTotal = 0

        for (let i = 0; i < orderHistory?.length; i++) {
            const order = orderHistory[i]

            if (order.creator_paid) paidTotal = paidTotal + order?.price * (1 - fee)
            else totToBePaid = totToBePaid + order?.price * (1 - fee)
        }

        setTotToBePaid(totToBePaid)
        setTotPaid(paidTotal)
    }

    function getLastDayOfMonthFormatted() {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const today = new Date();
        const currentMonth = today.getMonth();
        const nextMonth = (currentMonth + 1) % 12;
        const nextMonthFirstDay = new Date(today.getFullYear(), nextMonth, 1);
        const lastDayOfCurrentMonth = new Date(nextMonthFirstDay - 1);

        const day = lastDayOfCurrentMonth.getDate();
        const month = months[lastDayOfCurrentMonth.getMonth()];
        const year = lastDayOfCurrentMonth.getFullYear();

        const formattedDate = `${day} ${month}, ${year}`;

        return formattedDate;
    }

    const _renderFreeTrialEndDate = () => {
        var joinedDate = new Date(creatorShortInfo?.joined_at);
        
        return moment(joinedDate.setFullYear(joinedDate.getFullYear() + 1)).format('MMM DD, YYYY')
    }

    const _renderBusinessTerms = (fee) => {
        if (fee > 0) return <>Arvel's revenue-sharing policy ensures that as a valued contributor, you retain <Bold>{Math.floor((1 - itineraryArvelFee) * 100) + '%'}</Bold> of the sales subtotal while Arvel receives <Bold>{Math.floor((itineraryArvelFee) * 100) + '%'}</Bold>. The fees help Arvel to sustain, enhance, and actively promote the platform.</>
        else return <>In appreciation of your dedication as a power user, Arvel's revenue-sharing policy ensures that you will retain <Bold>100%</Bold> of the sales subtotal. This policy update will be in effect until <Bold>{_renderFreeTrialEndDate()}</Bold>.</>
    }

    const _renderItineraryHistoryContent = () => {
        if (!itineraryHistory || itineraryHistory.length < 1) return <div className='fc-empty-section'>
            <IconFolderSearch stroke={1} width='80' height='80' color='rgba(0, 0, 0, 0.7)' />
            <p>When you build itineraries, they will appear here</p>
        </div>

        return <>
            {itineraryHistory?.map(function (itinerary, index) {
                return <div key={`fc-itinerary-div-${index}`} className='fc-itinerary-div'>
                    <p className='fci-cell fci-cell-date'>{moment(itinerary?.created_at).format('MMM DD, YYYY')}</p>
                    <div className='fci-cell-breakline fci-cell-date-breakline' />
                    <p className='fci-cell fci-cell-trip-type'>{itinerary?.description}</p>
                    <div className='fci-cell-breakline fci-cell-trip-type-breakline' />
                    <p className='fci-cell fci-cell-trip-price'>{'$' + itinerary?.price}</p>
                    <div className='fci-cell-breakline fci-cell-trip-price-breakline' />
                    <p className='fci-cell fci-cell-arvel-fee'>{'$' + Math.round(itinerary?.price * itineraryArvelFee * 10) / 10}</p>
                    <div className='fci-cell-breakline fci-cell-arvel-fee-breakline' />
                    <p className='fci-cell fci-cell-total'>{'$' + Math.round(itinerary?.price * (1 - itineraryArvelFee) * 10) / 10}</p>
                    <div className='fci-cell-breakline fci-cell-breakline-status' />
                    <p className='fci-cell fci-cell-status'>{itinerary?.completed_at ? 'Completed' : 'In process'}</p>
                    <div className='fci-cell-breakline fci-cell-breakline-paid' />
                    <p className='fci-cell fci-cell-paid'>{itinerary?.creator_paid ? 'Yes' : 'No'}</p>
                    <ItineraryDetails itinerary={itinerary} itineraryArvelFee={itineraryArvelFee} />
                </div>
            })}
        </>
    }

    return (
        <>
            <Helmet>
                <title>{'Finance | Partner Portal'}</title>
            </Helmet>
            <div className="finance-container">
                <Header />
                <NavigationTabMobile />
                <Fade>
                    <div className='fc-container-line-1'>
                        <div className='fc-container-line-1-clm-1'>
                            <Card className="max-w-xs mx-auto">
                                {SectionHeaderPopUpDescription((windowWith > 600 ? 'Pending payment' : 'Pending'), 300, (windowWith > 600 ? 'right' : 'bottom'), <div>The gross amount that you will be transferred on <b>{getLastDayOfMonthFormatted()}</b>.</div>)}
                                <Metric style={{ marginTop: 5 }}>{'$ ' + totToBePaid.toLocaleString()}</Metric>
                            </Card>
                            <Card className="max-w-xs mx-auto">
                                {SectionHeaderPopUpDescription((windowWith > 600 ? 'Total paid' : 'Paid'), 300, (windowWith > 600 ? 'right' : 'bottom'), "The gross amount that you have already been transferred.")}
                                <Metric style={{ marginTop: 5 }}>{'$ ' + totPaid.toLocaleString()}</Metric>
                            </Card>
                        </div>
                        <Card>
                            <Title style={{ marginBottom: 3 }}>Business terms</Title>
                            <Text style={{ color: 'rgba(0,0,0,0.7)' }}>{_renderBusinessTerms(itineraryArvelFee)}</Text>

                            <Title style={{ marginTop: 15, marginBottom: 3 }}>Payments</Title>
                            <Text style={{ color: 'rgba(0,0,0,0.7)' }}>Arvel disburses payments on the last day of each month via Interac e-transfer, ensuring timely compensation for contributors.</Text>

                            <Title style={{ marginTop: 15, marginBottom: 3 }}>Content ownership</Title>
                            <Text style={{ color: 'rgba(0,0,0,0.7)' }}>Contributors retain complete ownership of the content shared on the platform. At any time, creators have the freedom to edit or unlist the places.</Text>
                        </Card>
                    </div>
                    <div className='fc-container-line-2'>
                        <Card>
                            <div className='fc-ibh-container'>
                                {SectionHeaderPopUpDescription("Itinerary history", 300, (windowWith > 600 ? 'right' : 'top'), "In this section, you'll find the history of all itineraries requested directly from you and associated payment breakdown.")}
                            </div>
                            <div className='fc-itinerary-table-header-div'>
                                <p className='fci-cell fci-cell-date'>Date</p>
                                <div className='fci-cell-breakline fci-cell-date-breakline' />
                                <p className='fci-cell fci-cell-trip-type'>Trip type</p>
                                <div className='fci-cell-breakline fci-cell-trip-type-breakline' />
                                <p className='fci-cell fci-cell-trip-price'>Trip price</p>
                                <div className='fci-cell-breakline fci-cell-trip-price-breakline' />
                                <p className='fci-cell fci-cell-arvel-fee'>Arvel fee</p>
                                <div className='fci-cell-breakline fci-cell-arvel-fee-breakline' />
                                <p className='fci-cell fci-cell-total'>Total</p>
                                <div className='fci-cell-breakline fci-cell-breakline-status' />
                                <p className='fci-cell fci-cell-status'>Status</p>
                                <div className='fci-cell-breakline fci-cell-breakline-paid' />
                                <p className='fci-cell fci-cell-paid'>Paid</p>
                            </div>
                            {_renderItineraryHistoryContent()}
                        </Card>
                    </div>
                </Fade>
            </div>
        </>
    );
}