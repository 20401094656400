import React, { useState, useEffect } from 'react';
import './Itineraries.css';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import { Card } from "@tremor/react";
import moment from "moment";
import DetailsModal from './Components/DetailsModal';
import { GetCreatorsItineraryOrders } from '../../Components/Helpers/Queries';
import { SectionHeaderPopUpDescription } from '../../Components/HelperFunctions'
import { IconMap2, IconFolderSearch } from '@tabler/icons-react';
import Header from '../../Components/Header';
import NavigationTabMobile from '../../Components/NavigationTabMobile';

export default function Itineraries() {
    const [pendingList, setPendingList] = useState([]);
    const [completedList, setCompletedList] = useState([]);
    const [windowWith, setWindowWidth] = useState(null);

    useEffect(function () {
        async function load() {
            let resultItineraryOrders = await GetCreatorsItineraryOrders()

            setPendingList(resultItineraryOrders?.filter(itinerary => !itinerary.completed_at))
            setCompletedList(resultItineraryOrders?.filter(itinerary => itinerary.completed_at))
            setWindowWidth(window.screen.width)
        };

        load();
    }, []);

    const _renderDueDate = (inputDateStr, daysToAdd) => {
        const inputDate = new Date(inputDateStr);
        inputDate.setDate(inputDate.getDate() + daysToAdd);
        const updatedDateStr = inputDate.toISOString();

        return moment(updatedDateStr).format('MMM DD, YYYY');
    }

    const _renderPendingItineraries = () => {
        if (!pendingList || pendingList?.length < 1) return <div className='ic-empty-section'>
            <IconMap2 stroke={1} width='80' height='80' color='rgba(0, 0, 0, 0.7)' />
            <p>When you receive new itinerary request, it will appear here</p>
        </div>

        return <>
            {
                pendingList?.map(function (itinerary, index) {
                    return <div key={`icp-itinerary-div-${index}`} className='ic-itinerary-div'>
                        <p className='ici-cell ici-cell-received'>{moment(itinerary?.created_at).format('MMM DD, YYYY')}</p>
                        <div className='ici-cell-breakline ici-cell-received-breakline' />
                        <p className='ici-cell ici-cell-due-date'>{_renderDueDate(itinerary?.created_at, 4)}</p>
                        <div className='ici-cell-breakline ici-cell-due-date-breakline' />
                        <p className='ici-cell ici-cell-trip-type'>{itinerary?.description}</p>
                        <div className='ici-cell-breakline ici-cell-trip-type-breakline' />
                        <p className='ici-cell ici-cell-destination'>{itinerary?.destination}</p>
                        <div className='ici-cell-breakline ici-cell-destination-breakline' />
                        <p className='ici-cell ici-cell-price'>{'$' + itinerary?.price}</p>
                        <div className='ici-cell-breakline ici-cell-price-breakline' />
                        <DetailsModal itineraryId={itinerary?.id} />
                    </div>
                })
            }
        </>
    }

    const _renderCompletedItineraries = () => {
        if (!completedList || completedList?.length < 1) return <div className='ic-empty-section'>
            <IconFolderSearch stroke={1} width='80' height='80' color='rgba(0, 0, 0, 0.7)' />
            <p>When you build itineraries, they will appear here</p>
        </div>

        return <>
            {
                completedList?.map(function (itinerary, index) {
                    return <div key={`icc-itinerary-div-${index}`} className='ic-itinerary-div'>
                        <p className='ici-cell ici-cell-received'>{moment(itinerary?.created_at).format('MMM DD, YYYY')}</p>
                        <div className='ici-cell-breakline ici-cell-received-breakline' />
                        <p className='ici-cell ici-cell-completed'>{moment(itinerary?.completed_at).format('MMM DD, YYYY')}</p>
                        <div className='ici-cell-breakline ici-cell-completed-breakline' />
                        <p className='ici-cell ici-cell-trip-type'>{itinerary?.description}</p>
                        <div className='ici-cell-breakline ici-cell-trip-type-breakline' />
                        <p className='ici-cell ici-cell-destination'>{itinerary?.destination}</p>
                        <div className='ici-cell-breakline ici-cell-destination-breakline' />
                        <p className='ici-cell ici-cell-price'>{'$' + itinerary?.price}</p>
                        <div className='ici-cell-breakline ici-cell-price-breakline' />
                        <DetailsModal itineraryId={itinerary?.id} />
                    </div>
                })
            }
        </>
    }

    return (
        <>
            <Helmet>
                <title>{'Itineraries | Partner Portal'}</title>
            </Helmet>
            <div className="itineraries-container">
                <Header/>
                <NavigationTabMobile/>
                <Fade>
                    <Card className='ic-pending-itinerary-card'>
                        <div className='ic-pending-card-title'>
                            {SectionHeaderPopUpDescription('Pending itineraries', 300, (windowWith > 600 ? 'right' : 'bottom'), "In this section, you'll find requested itineraries that need to be completed.")}
                        </div>
                        <div className='ic-itinerary-table-header-div'>
                            <p className='ici-cell ici-cell-received'>Received</p>
                            <div className='ici-cell-breakline ici-cell-received-breakline' />
                            <p className='ici-cell ici-cell-due-date'>Due date</p>
                            <div className='ici-cell-breakline ici-cell-due-date-breakline' />
                            <p className='ici-cell ici-cell-trip-type'>Trip type</p>
                            <div className='ici-cell-breakline ici-cell-trip-type-breakline' />
                            <p className='ici-cell ici-cell-destination'>Destination</p>
                            <div className='ici-cell-breakline ici-cell-destination-breakline' />
                            <p className='ici-cell ici-cell-price'>Price</p>
                            <div style={{ backgroundColor: 'transparent' }} className='ici-cell-breakline ici-cell-price-breakline' />
                            <p className='ici-cell ici-cell-btn' />
                        </div>
                        {_renderPendingItineraries()}
                    </Card>
                    <Card style={{ marginTop: 30 }} className='ic-completed-itinerary-card'>
                        <div className='ic-completed-card-title'>
                            {SectionHeaderPopUpDescription('Completed itineraries', 300, (windowWith > 600 ? 'right' : 'top'), "In this section, you'll find itineraries that you have already completed.")}
                        </div>
                        <div className='ic-itinerary-table-header-div'>
                            <p className='ici-cell ici-cell-received'>Received</p>
                            <div className='ici-cell-breakline ici-cell-received-breakline' />
                            <p className='ici-cell ici-cell-completed'>Completed</p>
                            <div className='ici-cell-breakline ici-cell-completed-breakline' />
                            <p className='ici-cell ici-cell-trip-type'>Trip type</p>
                            <div className='ici-cell-breakline ici-cell-trip-type-breakline' />
                            <p className='ici-cell ici-cell-destination'>Destination</p>
                            <div className='ici-cell-breakline ici-cell-destination-breakline' />
                            <p className='ici-cell ici-cell-price'>Price</p>
                            <div style={{ backgroundColor: 'transparent' }} className='ici-cell-breakline ici-cell-price-breakline' />
                            <p className='ici-cell ici-cell-btn' />
                        </div>
                        {_renderCompletedItineraries()}
                    </Card>
                </Fade>
            </div>
        </>
    );
}