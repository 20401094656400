import React, { useState, useEffect } from 'react';
import '../AddExperience.css';
import { Autocomplete } from '@mantine/core';
import { GetNodeNamesList } from '../../../Components/Helpers/Queries';

export default function AddInfoName(props) {
    const { errorMsg } = props;
    const [name, setName] = useState('');
    const [namesListAutocomplete, setNamesListAutocomplete] = useState([]);
    const [error, setError] = useState(null);

    async function handleNameChange(name) {
        setName(name)
        let resultGetNamesList = await GetNodeNamesList(6, name)

        setNamesListAutocomplete(resultGetNamesList)
        props.handleNameChange(name)
    }

    useEffect(function () {
        setError(errorMsg)
    }, [errorMsg]);

    return (
        <>
            {namesListAutocomplete && <div className={(!error ? 'npc-place-name-div' : 'npc-place-name-div error')}>
                <Autocomplete
                    limit={6}
                    if='name-autocomplete-input'
                    placeholder="Type name here.."
                    value={name}
                    data={namesListAutocomplete}
                    onChange={(value) => handleNameChange(value)}
                    onItemSubmit={(item) => props.handlePlaceExist(item.id)}
                />
                {error && <p className='npc-place-name-error'>{error}</p>}
            </div>}
        </>
    );
}