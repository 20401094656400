import React from 'react';
import '../AddExperience.css';
import { Card } from "@tremor/react";
import DynamicIcon from '../../../Components/Helpers/DynamicIcon';

export default function UploadingPlace(props) {
    const { isUploading, uploadSucess, uploadError } = props;

    const _renderContent = () => {
        if (uploadSucess) return pageBody('addNewPlaceLoadingSuccess', 'Done!', "The experience has been successfully added to your profile.")
        else if (isUploading) return pageBody('addNewPlaceLoading', 'Uploading...', "It might take a couple of minutes. Don't refresh the page")
        else if (uploadError) return pageBody('addNewPlaceLoadingError', 'Opps..Something went wrong', "Please reload the page, and try again")
    }

    const pageBody = (icon_name, header, body) => {
        return <>
            <DynamicIcon type={icon_name} width={250} height={200} />
            <h3>{header}</h3>
            <p>{body}</p>
        </>
    }

    return (
        <Card className='npc-place-loading'>
            {uploadSucess && <button className='cancel-button ane-close-btn' onClick={() => props.handleClose()}>Close</button>}
            <div className='npc-pl-container'>
                {_renderContent()}
            </div>
        </Card>
    );
}