import React, { useState } from 'react';
import '../Finance.css';
import { Modal } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import moment from "moment";

export default function ItineraryDetails(props) {
    const { itinerary, itineraryArvelFee } = props;
    const [show, setShow] = useState(false);

    return (
        <>
            <button className='ic-cell-see-more' onClick={() => setShow(true)}>see more</button>
            <Modal
                centered={true}
                size='xl'
                withCloseButton={false}
                opened={show}
                onClose={() => setShow(false)}
                className='itinerary-details-modal'>
                <button className='ic-close-btn' onClick={() => setShow(false)}>
                    <IconX stroke={2.5} width='20' height='20' color='rgb(217, 52, 113)' />
                </button>
                <div className='id-body'>
                    <div className='row'>
                        <p className='title'>Date</p>
                        <p className='value'>{moment(itinerary?.created_at).format('MMM DD, YYYY')}</p>
                    </div>
                    <div className='breakline'/>
                    <div className='row'>
                        <p className='title'>Trip type</p>
                        <p className='value'>{itinerary?.description}</p>
                    </div>
                    <div className='breakline'/>
                    <div className='row'>
                        <p className='title'>Trip price</p>
                        <p className='value'>{'$' + itinerary?.price}</p>
                    </div>
                    <div className='breakline'/>
                    <div className='row'>
                        <p className='title'>Arvel fee</p>
                        <p className='value'>{'$' + Math.round(itinerary?.price * itineraryArvelFee * 10) / 10}</p>
                    </div>
                    <div className='breakline'/>
                    <div className='row'>
                        <p className='title'>Total</p>
                        <p className='value'>{'$' + Math.round(itinerary?.price * (1 - itineraryArvelFee) * 10) / 10}</p>
                    </div>
                    <div className='breakline'/>
                    <div className='row'>
                        <p className='title'>Status</p>
                        <p className='value'>{itinerary?.completed_at ? 'Completed' : 'In process'}</p>
                    </div>
                </div>
            </Modal>
        </>
    );
}