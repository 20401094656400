import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../Sign.css';
import Validate from 'validate.js';
import { withRouter } from 'react-router-dom';
import { SignInAsync } from '../../../Components/Helpers/Authenticator';
import DynamicIcon from '../../../Components/Helpers/DynamicIcon';

function SignInCode(props) {
    const { email } = props
    const [loading, setLoading] = useState(false);
    const [inputErrorMsg, setInputErrorMsg] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [chaObj, setChaObj] = useState({
        cha_1: null,
        cha_2: null,
        cha_3: null,
        cha_4: null,
        cha_5: null,
        cha_6: null
    });

    const constraints = {
        cha_1: {
            presence: {
                message: 'Required'
            }
        },
        cha_2: {
            presence: {
                message: 'Required'
            }
        },
        cha_3: {
            presence: {
                message: 'Required'
            }
        },
        cha_4: {
            presence: {
                message: 'Required'
            }
        },
        cha_5: {
            presence: {
                message: 'Required'
            }
        },
        cha_6: {
            presence: {
                message: 'Required'
            }
        }
    }

    useEffect(() => {
        const onPaste = (e) => {
            if (e?.target?.localName !== 'input') return;
            let paste = (e.clipboardData || window.clipboardData).getData('text');
            setChaObj({
                ...chaObj,
                cha_1: paste.slice(0, 1),
                cha_2: paste.slice(1, 2),
                cha_3: paste.slice(2, 3),
                cha_4: paste.slice(3, 4),
                cha_5: paste.slice(4, 5),
                cha_6: paste.slice(5, 6)
            })

            document.activeElement.blur();
        }

        window.addEventListener("paste", onPaste);
        return () => window.removeEventListener("paste", onPaste);
    }, []);

    function handleOnKeyPress(e) {
        if (e && e.charCode === 13) submitSignIn();
        else setInputErrorMsg(null);
    }

    const submitSignIn = async () => {
        setLoading(true)

        let check = Validate({
            cha_1: chaObj?.cha_1,
            cha_2: chaObj?.cha_2,
            cha_3: chaObj?.cha_3,
            cha_4: chaObj?.cha_4,
            cha_5: chaObj?.cha_5,
            cha_6: chaObj?.cha_6
        }, constraints, { fullMessages: false });

        if (check) {
            setInputErrorMsg('Required')
            setLoading(false)
            return
        }

        const code = parseInt(`${chaObj?.cha_1}${chaObj?.cha_2}${chaObj?.cha_3}${chaObj?.cha_4}${chaObj?.cha_5}${chaObj?.cha_6}`)

        var result = await SignInAsync(email, code);
        if (!result) {
            setLoading(false);
            setErrorMessage("Something went wrong, please try again");
        } else {
            window.location.href = "/profile";
            setLoading(true);
            return;
        }
    }

    function _renderSignButtopn() {
        if (loading) return <DynamicIcon type='loadingWhiteCircle' width={39} height={39} />
        else return 'Sign in'
    }

    return (
        <div className="sign-sec sign-in sign-in-email-container">
            <div className="sign-body">
                <div className="sign-header">We sent the code to <b style={{ color: 'rgb(10, 50, 77)' }}>{email}</b>. Don't forget to check junk folder 🙂</div>
                <div className={`sie-input-div ${inputErrorMsg ? 'error' : ''}`}>
                    <input name="char-1"
                        id='sii-char-1'
                        autoFocus
                        className='si-code-input-1'
                        maxLength={1}
                        value={chaObj?.cha_1 || ''}
                        type="text"
                        placeholder=""
                        onChange={({ target: { value } }) => {
                            setChaObj({ ...chaObj, cha_1: value });
                            setInputErrorMsg(null)
                            if (value?.length > 0) document.getElementById('sii-char-2')?.focus()
                        }}
                        onKeyPress={handleOnKeyPress} />
                    <input name="char-2"
                        id='sii-char-2'
                        className='si-code-input-2'
                        maxLength={1}
                        value={chaObj?.cha_2 || ''}
                        type="text"
                        placeholder=""
                        onChange={({ target: { value } }) => {
                            setChaObj({ ...chaObj, cha_2: value });
                            setInputErrorMsg(null)
                            if (value?.length > 0) document.getElementById('sii-char-3')?.focus()
                        }}
                        onKeyPress={handleOnKeyPress} />
                    <input name="char-3"
                        id='sii-char-3'
                        className='si-code-input-3'
                        maxLength={1}
                        value={chaObj?.cha_3 || ''}
                        type="text"
                        placeholder=""
                        onChange={({ target: { value } }) => {
                            setChaObj({ ...chaObj, cha_3: value });
                            setInputErrorMsg(null)
                            if (value?.length > 0) document.getElementById('sii-char-4')?.focus()
                        }}
                        onKeyPress={handleOnKeyPress} />
                    <input name="char-4"
                        id='sii-char-4'
                        className='si-code-input-4'
                        maxLength={1}
                        value={chaObj?.cha_4 || ''}
                        type="text"
                        placeholder=""
                        onChange={({ target: { value } }) => {
                            setChaObj({ ...chaObj, cha_4: value });
                            setInputErrorMsg(null)
                            if (value?.length > 0) document.getElementById('sii-char-5')?.focus()
                        }}
                        onKeyPress={handleOnKeyPress} />
                    <input name="char-5"
                        id='sii-char-5'
                        className='si-code-input-5'
                        maxLength={1}
                        value={chaObj?.cha_5 || ''}
                        type="text"
                        placeholder=""
                        onChange={({ target: { value } }) => {
                            setChaObj({ ...chaObj, cha_5: value });
                            setInputErrorMsg(null)
                            if (value?.length > 0) document.getElementById('sii-char-6')?.focus()
                        }}
                        onKeyPress={handleOnKeyPress} />
                    <input name="char-6"
                        id='sii-char-6'
                        className='si-code-input-6'
                        maxLength={1}
                        value={chaObj?.cha_6 || ''}
                        type="text"
                        placeholder=""
                        onChange={({ target: { value } }) => { setChaObj({ ...chaObj, cha_6: value }); setInputErrorMsg(null) }}
                        onKeyPress={handleOnKeyPress} />
                    {inputErrorMsg && <span className="helper-txt">{inputErrorMsg}</span>}
                </div>
                <button className="btn-sign" onClick={submitSignIn}>{_renderSignButtopn()}</button>
                <span className={`error-txt ${errorMessage ? 'sign-in-error-msg-show' : ''}`}>
                    {errorMessage}
                </span>
            </div>
        </div>
    );
}

SignInCode.propTypes = {
    setIsLoggedIn: PropTypes.func.isRequired
}

export default withRouter(SignInCode)