import React, { useState } from 'react'
import '../Profile.css';
import { IconPencil } from '@tabler/icons-react';
import { UpdateCreatorsProfileImage } from '../../../Components/Helpers/Mutations';

export default function UpdateProfileImg(props) {
    const [isLoading, setIsLoading] = useState(false)

    async function handleImageSelected(e) {
        props.imageUpdateStart()
        setIsLoading(true)

        const reader = new FileReader()
        const file = e.target.files[0]

        reader.onload = function (event) {
            const img = new Image();

            img.onload = function () {
                let desiredWidth = 0;
                let desiredHeight = 0;
                let cropWidth = 0;
                let cropHeight = 0;

                if(img.width > img.height){
                    desiredHeight = 320
                    desiredWidth = desiredHeight / img.height * img.width
                    cropWidth = desiredHeight
                    cropHeight = desiredHeight
                } else{
                    desiredWidth = 320
                    desiredHeight = desiredWidth / img.width * img.height
                    cropWidth = desiredWidth
                    cropHeight = desiredWidth
                }

                // Create a new Image element to store the resized image
                const resizedImg = new Image();

                // Draw the original image on the resized Image element with the desired dimensions
                const canvas = document.createElement('canvas');
                canvas.width = desiredWidth;
                canvas.height = desiredHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, desiredWidth, desiredHeight);
                resizedImg.src = canvas.toDataURL();

                // Use Promise to wait for the resized image to load
                const waitForResizedImgLoad = new Promise((resolve) => {
                    resizedImg.onload = () => resolve();
                });

                waitForResizedImgLoad.then(() => {
                    // Calculate crop dimensions
                    const cropX = (resizedImg.width - cropWidth) / 2;
                    const cropY = (resizedImg.height - cropHeight) / 2;

                    // Create a canvas to draw the cropped image
                    const cropCanvas = document.createElement('canvas');
                    cropCanvas.width = cropWidth;
                    cropCanvas.height = cropHeight;
                    const cropCtx = cropCanvas.getContext('2d');

                    // Draw the resized image on the crop canvas, cropping it
                    cropCtx.drawImage(resizedImg, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

                    // Get the Blob data of the cropped image from the crop canvas
                    cropCanvas.toBlob(async function (blob) {
                        // Create a new File object with the Blob data
                        const croppedFile = new File([blob], file.name, { type: file.type });

                        const resultUpdateimage = await UpdateCreatorsProfileImage(croppedFile)
                        
                        setTimeout(() => {
                            props.imageUpdateComplete(resultUpdateimage)
                            setIsLoading(false)
                        }
                            , 1500)

                    }, file.type, 1); // Optionally, you can specify the image quality (0.8 in this case)
                });
            };

            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    }

    return (
        <label className="pc-img-action-btn pc-iab-edit">
            <input style={{ display: 'none' }} type="file" accept='image/png, image/jpg' disabled={isLoading} onChange={handleImageSelected} />
            <IconPencil stroke={1.9} width='18' height='18' color='#fff' />
        </label>
    );
}