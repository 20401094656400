import React, { useEffect, useState } from 'react';
import '../AddExperience.css';
import { IconBoxMultiple } from '@tabler/icons-react';
import DragAndDrop from './DragAndDropReorderPopUp';

export default function ImageReorderPopUp(props) {
    const { files } = props;
    const [orderedList, setOrderedList] = useState(null);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpMsg, setShowPopUpMsg] = useState(false);
    const [seenPopUpMsg, setSeenPopUpMsg] = useState(false);

    useEffect(function () {
        setOrderedList(files);
    }, [showPopUp, files]);

    useEffect(function () {
        if (!seenPopUpMsg && showPopUp) {
            setTimeout(() => {
                setShowPopUpMsg(true)
            }
                , 1500)

            setTimeout(() =>
                setShowPopUpMsg(false)
                , 8000)
            setSeenPopUpMsg(true)
        }
    }, [showPopUp]);

    const handleShowPopUpClicked = () => {
        if (showPopUp) {
            setShowPopUp(false)
            setShowPopUpMsg(false)
        } else setShowPopUp(true)
    }

    return (
        <>
            <button style={{ backgroundColor: (showPopUp ? 'rgba(26, 26, 26, .05)' : 'rgba(26, 26, 26, .8)') }} className='image-slide-reorder-btn' onClick={handleShowPopUpClicked}>
                <IconBoxMultiple stroke={2} width='18' color={showPopUp ? '#000' : '#fff'} />
            </button>
            <div className={`image-reorder-container ${showPopUp ? 'irc-show' : ''}`}>
                <div className={`click-and-drag-info-popup ${showPopUpMsg ? 'cad-ip-show-show' : ''}`}>Click and drag to reorder</div>
                <div style={{ overflow: 'hidden' }}>
                    <DragAndDrop images={orderedList}
                        handleImageSelected={(img) => props.handleImageSelected(img)}
                        handleImageRemoved={(img) => props.handleImageRemoved(img)}
                        handleListSorted={(list) => props.handleListSorted(list)} />
                </div>
            </div>
        </>
    );
}