import React, { useState, useEffect } from 'react';
import './AddExperience.css';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import SelecFiles from './Components/SelecFiles';
import ReviewFiles from './Components/ReviewFiles';
import AddIno from './Components/AddInfo';
import UploadingPlace from './Components/UploadingPlace';
import { CreatorsAddNewPlace, SendEmailNotification } from '../../Components/Helpers/Mutations';
import { SignedInCreatorsGetShortInfo } from '../../Components/Helpers/Queries';
import Header from '../../Components/Header';
import NavigationTabMobile from '../../Components/NavigationTabMobile';

export default function AddExperience() {
    const [creatorsShortInfo, setCreatorsShortInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadSucess, setUploadSucess] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [showSection, setShowSection] = useState(0);
    const [completeImageObjs, setCompleteImageObjs] = useState(null);
    const [displayImageObjs, setDisplayImageObjs] = useState(null);

    useEffect(() => {
        async function load() {
            let resultCreatorShortInfo = await SignedInCreatorsGetShortInfo()

            setCreatorsShortInfo(resultCreatorShortInfo)
        };

        load();
    }, []);

    const handleFilesSelected = (completeImageObjects) => {
        setCompleteImageObjs(completeImageObjects);
        setDisplayImageObjs(completeImageObjects);
        setIsLoading(true);
        setTimeout(() => {
            setShowSection(1)
            setIsLoading(false)
        }
            , 1500)
    }

    const handleImageRemoved = (img) => {
        if (displayImageObjs?.length === 1) removeAllSelectedImages();
        else {
            setCompleteImageObjs(completeImageObjs.filter(e => e.id !== img.id));
            setDisplayImageObjs(displayImageObjs.filter(e => e.id !== img.id));
        }
    }

    const handleListSorted = (list) => {
        setCompleteImageObjs(list);
        setDisplayImageObjs(list);
    }

    const removeAllSelectedImages = () => {
        setCompleteImageObjs(null)
        setDisplayImageObjs(null);
        setShowSection(0);
    }

    async function handleAddNewPlace(placeObj) {
        setIsUploading(true);
        setShowSection(3);
        let finalMediaList = [];

        for (let i = 0; i < completeImageObjs.length; i++) finalMediaList.push(completeImageObjs[i].file)
        placeObj["media"] = finalMediaList;

        let resultAddNewPlace = await CreatorsAddNewPlace(placeObj)
        if (resultAddNewPlace) {
            setUploadSucess(true)
            await SendEmailNotification('Arvel Portal: New experience added', `New place added by ${creatorsShortInfo?.instagram}`, `Experience name: ${placeObj?.name}`)
        }
        else {
            setUploadError(true)
            await SendEmailNotification('Arvel Portal: New experience added', `**Error** New place add by ${creatorsShortInfo?.instagram}`, `Experience name: ${placeObj?.name}`)
        }
        setIsUploading(false)
    }

    const handleCloseUploadingPlaceScreen = () => {
        setIsUploading(false)
        setUploadSucess(false)
        setUploadError(false)
        setShowSection(0)
    }

    const _renderContent = () => {
        if (showSection === 0) return <SelecFiles isLoading={isLoading} filesSelected={handleFilesSelected} loadingStarted={() => setIsLoading(true)} loadingEnded={() => setIsLoading(false)} />
        else if (showSection === 1) return <ReviewFiles isLoading={isLoading} files={displayImageObjs} handleBack={() => setShowSection(showSection - 1)} handleImageRemoved={handleImageRemoved} handleListSorted={handleListSorted} handleNextClicked={() => setShowSection(2)} />
        else if (showSection === 2) return <AddIno handleBack={() => setShowSection(showSection - 1)} handleAddNewPlace={handleAddNewPlace} />
        else if (showSection === 3) return <UploadingPlace isUploading={isUploading} uploadSucess={uploadSucess} uploadError={uploadError} handleClose={handleCloseUploadingPlaceScreen} />
    }

    return (
        <>
            <Helmet>
                <title>{'Add Place | Partner Portal'}</title>
            </Helmet>
            <div className="add-place-container">
                <Header />
                <NavigationTabMobile />
                <Fade>
                    {_renderContent()}
                </Fade>
            </div>
        </>
    );
}